import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Empty } from 'antd';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoadVideos } from '../../hooks/use-load-videos';
import { LocaleKeys } from '../../locale';
import { useMeSelector } from '../../state/me/selectors';
import { ESortOption } from '../../types/video';
import { sortByDateField } from '../../utils/video';
import { VideoColItem } from './uploaded-items/video-col-item';
import { VideoItem } from './uploaded-items/video-item';

const EmptyStyled = styled(Empty)`
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  align-self: center;
`;

const LoadingWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 54px;
`;

export const Videos: FC<{
  playlistEditMode?: boolean;
  handleChangeVideoList?: (videoId: string) => void;
  selectedVideos?: string[];
  sortOption?: ESortOption;
  layout?: string;
}> = ({
  playlistEditMode,
  handleChangeVideoList,
  selectedVideos,
  sortOption,
  layout = 'grid',
}) => {
  const { data, loading } = useLoadVideos();
  const { mePending } = useMeSelector();
  const { t } = useTranslation([LocaleKeys.VIDEO]);

  return (
    <Fragment>
      {loading || mePending ? (
        <LoadingWrapper>
          <LoadingOutlined />
        </LoadingWrapper>
      ) : (
        <Fragment>
          {[...data]
            .sort((a, b) =>
              sortByDateField(a, b, 'createdAt', sortOption === ESortOption.OLD)
            )
            .map((el) =>
              layout.includes('grid') ? (
                <VideoItem
                  key={el.id}
                  videoData={el}
                  editMode={playlistEditMode}
                  handleChangeVideoList={handleChangeVideoList}
                  selectedVideos={selectedVideos}
                />
              ) : (
                <VideoColItem
                  key={el.id}
                  videoData={el}
                  editMode={playlistEditMode}
                  handleChangeVideoList={handleChangeVideoList}
                  selectedVideos={selectedVideos}
                />
              )
            )}
          {data.length === 0 && (
            <EmptyStyled
              description={t('videos.noVideo')}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
