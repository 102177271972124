import styled from '@emotion/styled';
import { Popconfirm, message, notification } from 'antd';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { LocaleKeys } from '../../../locale';
import { TLinkListed } from '../../../types/responses/video';
import {
  getPlayerEditLinkPath,
  getPlayerLink,
  getVideoLinkStatisticPath,
} from '../../../utils/paths';
import CopyIcon from '../../icons/copy-icon';
import DuplicateIcon from '../../icons/duplicate-icon';
import SettingsIcon from '../../icons/settings-icon';
import StatIcon from '../../icons/stat-icon';
import { Trash } from '../../icons/trash';

const VideoLinkWrapper = styled('div')`
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 64px;
  }
`;

const ColContent = styled('div')`
  background-color: ${({ theme }) => theme.COLORS.BLACK._400};
  border-radius: 16px;
  height: 100px;
  @media (max-width: 990px) {
    height: 70px;
  }
`;

const CenteredCol = styled(ColContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s ease-out;
  min-width: 90px;
  &.danger {
    svg path {
      transition: fill 0.3s ease-out;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.BLACK._300};

    &.danger {
      background-color: ${({ theme }) => theme.COLORS.DANGER};
      svg path {
        fill: ${({ theme }) => theme.COLORS.WHITE._100};
      }
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 990px) {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  @media (max-width: 540px) {
    min-width: 90px;
  }
`;

const ContentText = styled('div')`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;

  @media (max-width: 990px) {
    font-size: 14px;
  }
`;

const ContentSubText = styled('div')`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  @media (max-width: 1080px) {
    font-size: 10px;
  }

  @media (max-width: 990px) {
    &.ghost {
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
  }

  color: ${({ theme }) => theme.COLORS.WHITE._100};

  &.ghost {
    color: ${({ theme }) => theme.COLORS.BLACK._700};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
`;

const Title = styled('span')`
  display: inline-block;
  padding-left: 4px;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
  color: ${({ theme }) => theme.COLORS.BLACK._700};
`;

type TProps = TLinkListed & {
  index: number;
  handleDeleteLink: (id: string) => void;
  handleDuplicate: (id: string) => void;
  setButtonWrapperWidth: (width: number) => void;
};

const RowsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 8px 0px;
  position: relative;

  @media (max-width: 989px) {
    flex-direction: column;
    gap: 10px;
  }

  .abs-container {
    max-width: 180px;
    width: 100%;
    flex: 1 1 0;
    min-width: 100px;
    ${CenteredCol} {
      height: 210px;
      @media (max-width: 990px) {
        height: 150px;
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }
    @media (max-width: 989px) and (min-width: 541px) {
      display: none;
      min-width: 90px;
    }
  }
  .info-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    gap: 10px;
    max-width: 1120px;
    width: 100%;
    flex: 1 1 auto;

    .content-text {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      @media (max-width: 990px) {
        font-size: 16px;
      }
    }

    .col-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 30px;
      padding-left: 16px;
    }

    .video-title_wrapper {
      padding: 16px;
    }
    .title-text {
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 50px;
    }
    @media (min-width: 1230px) {
      .video-title {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
      }
    }

    @media (max-width: 990px) {
      .col-content {
        gap: 18px;
      }
    }

    @media (min-width: 540px) {
      .video-title {
        grid-column-start: 1;
        grid-column-end: 4;
      }
      min-width: 470px;
    }
    @media (max-width: 540px) {
      display: flex;
      flex-wrap: wrap;

      .video-title {
        flex: 0 0 62%;
      }
      .video-views {
        flex: 1;
        min-width: 100px;
      }
      .info-text {
        flex: 1 1 32%;
      }
    }
  }

  .button-wrapper {
    display: flex;
    gap: 10px;
    width: 100%;
    flex: 1 1 0;

    .button-text {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      text-align: center;
    }

    @media (min-width: 990px) {
      max-width: 560px;
      min-width: 330px;
      .mobile-copy {
        display: none;
      }
    }
    @media (max-width: 990px) {
      .button-text {
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        max-width: 60px;
      }
      min-width: 300px;
    }

    @media (max-width: 540px) {
      .mobile-copy {
        display: none;
      }
    }
  }

  .button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    max-width: 370px;
    width: 100%;

    @media (max-width: 989px) and (min-width: 541px) {
      display: flex;
      flex-wrap: nowrap;
      gap: 10px;
      max-width: 100%;

      > * {
        flex: 1;
      }
    }
  }
`;

export const VideoLink: FC<TProps> = ({
  index,
  id,
  videoSetting,
  handleDeleteLink,
  handleDuplicate,
  videoId,
  description,
  clicksCount,
  setButtonWrapperWidth,
}) => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const copyLink = useCallback(async () => {
    const linkHref = getPlayerLink(id);

    try {
      await navigator.clipboard.writeText(linkHref);
      message.success(t('video.link.copy.success'));
    } catch (e) {
      notification.error({
        message: t('video.link.copy.error'),
        description: (
          <React.Fragment>
            {t('video.link.copy.byYourself')}{' '}
            <a href={linkHref} target="_blank">
              {linkHref}
            </a>
          </React.Fragment>
        ),
      });
    }
  }, [id]);

  const handleDelete = () => {
    handleDeleteLink(id);
  };

  const onHandleDuplicate = () => {
    handleDuplicate(id);
  };

  return (
    <VideoLinkWrapper>
      <div>
        <Title>
          {t('video.link.title')}
          {index + 1}
        </Title>
        <Title>/</Title>
        <Title>{videoSetting?.title || t('statistics.noName')}</Title>
      </div>
      <RowsWrapper>
        <div className="info-container">
          <div className="video-title">
            <ColContent className="video-title_wrapper">
              <ContentText className="title-text">
                {description || t('statistics.link.noDescription')}
              </ContentText>
            </ColContent>
          </div>
          <div className="video-views">
            <ColContent className="col-content">
              <ContentText className="content-text">
                {clicksCount ?? 0}
              </ContentText>
              <ContentSubText className="ghost">
                {t('statistics.link.views')}
              </ContentSubText>
            </ColContent>
          </div>
          <div className="info-text">
            <ColContent className="col-content">
              <ContentText className="content-text">
                {videoSetting.increaseAttentionValue ?? 0}%
              </ContentText>
              <ContentSubText className="ghost">
                {t('statistics.link.attention')}
              </ContentSubText>
            </ColContent>
          </div>
          <div className="info-text">
            <ColContent className="col-content">
              <ContentText className="content-text">
                {videoSetting.minimumAttentionLimitValue ?? 0}%
              </ContentText>
              <ContentSubText className="ghost">
                {t('statistics.link.involvement')}
              </ContentSubText>
            </ColContent>
          </div>
        </div>
        <div className="button-wrapper">
          <div className="button-container">
            <a href={getVideoLinkStatisticPath(videoId, id)}>
              <CenteredCol>
                <StatIcon />
                <ContentSubText className="button-text">
                  {t('video.link.statistics')}
                </ContentSubText>
              </CenteredCol>
            </a>
            <NavLink to={getPlayerEditLinkPath(videoId, id)}>
              <CenteredCol>
                <SettingsIcon />
                <ContentSubText className="button-text">
                  {t('video.link.settings')}
                </ContentSubText>
              </CenteredCol>
            </NavLink>
            <CenteredCol onClick={onHandleDuplicate}>
              <DuplicateIcon />
              <ContentSubText className="button-text">
                {t('video.link.duplicate.title')}
              </ContentSubText>
            </CenteredCol>
            <Popconfirm
              title={t('video.link.deleting.title')}
              description={t('video.link.deleting.description')}
              onConfirm={handleDelete}
              okText={t('buttons.deleting.success')}
              cancelText={t('buttons.cancel')}
              placement="bottom"
              okButtonProps={{
                danger: true,
                type: 'primary',
              }}
            >
              <CenteredCol className="danger">
                <Trash />
                <ContentSubText className="button-text">
                  {t('video.link.delete')}
                </ContentSubText>
              </CenteredCol>
            </Popconfirm>
            <div className="mobile-copy">
              <CenteredCol onClick={copyLink}>
                <CopyIcon />
                <ContentSubText className="button-text">
                  {t('video.link.copy.title')}
                </ContentSubText>
              </CenteredCol>
            </div>
          </div>
          <div className="abs-container">
            <CenteredCol onClick={copyLink}>
              <CopyIcon />
              <ContentSubText className="button-text">
                {t('video.link.copy.title')}
              </ContentSubText>
            </CenteredCol>
          </div>
        </div>
      </RowsWrapper>
    </VideoLinkWrapper>
  );
};
