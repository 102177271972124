import styled from '@emotion/styled';

export const ContentWrapper = styled('div')<{ layout: string }>`
  display: grid;
  grid-template-columns: ${({ layout }) =>
    layout.includes('grid') ? 'repeat(4, 1fr)' : '1fr'};
  column-gap: 20px;
  row-gap: 40px;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  @media (max-width: 1440px) {
    grid-template-columns: ${({ layout }) =>
      layout.includes('grid') ? 'repeat(3, 1fr)' : '1fr'};
  }

  @media (max-width: 1120px) {
    grid-template-columns: ${({ layout }) =>
      layout.includes('grid') ? 'repeat(2, 1fr)' : '1fr'};
  }

  @media (max-width: 480px) {
    grid-template-columns: ${({ layout }) =>
      layout.includes('grid') ? 'repeat(1, 1fr)' : '1fr'};
  }

  @media (max-width: 1250px) {
    justify-content: center;
  }

  @media (max-width: 948px) {
  }
`;
