import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import { FC, PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LocaleKeys } from '../../locale';
import LayoutGridIcon from '../icons/layout-grid-icon';
import LayoutTableIcon from '../icons/layout-table-icon';
import { PageBackButton } from './page-back-button';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LayoutWrapper = styled('div')`
  cursor: pointer;
  height: 24px;
  width: auto;

  svg {
    height: 24px;
    width: auto;
  }
`;

export const LS_LAYOUT_VALUE_LEY_VIDEO_PAGE = 'video-layout';
export const LS_LAYOUT_VALUE_LEY_PLAYLIST_PAGE = 'playlist-layout';
export const LS_LAYOUT_VALUE_LEY_PLAYLIST_LIST_PAGE = 'playlist-list-layout';

export const TitleWithLayout: FC<
  PropsWithChildren<{
    layout: string;
    onChange: (layout: any) => void;
  }>
> = ({ layout, onChange }) => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);

  const handleChange = useCallback(() => {
    onChange(layout.includes('col') ? 'gridplaylist' : 'colplaylist');
  }, [layout]);

  const navigate = useNavigate();

  return (
    <Wrapper>
      <PageBackButton
        text={t('buttons.goBackToPlaylistList')}
        onClick={() => navigate('/uploaded/playlists')}
      />
      <Tooltip placement="left" title={t('playlist.sort')}>
        <LayoutWrapper onClick={handleChange}>
          {layout.includes('col') ? <LayoutTableIcon /> : <LayoutGridIcon />}
        </LayoutWrapper>
      </Tooltip>
    </Wrapper>
  );
};
