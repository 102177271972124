import styled from '@emotion/styled';
import { Checkbox, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import emptyVideoPreview from '../../../assets/empty-video.svg';
import { LocaleKeys } from '../../../locale';
import { useLocaleSelector } from '../../../state/locale/selector';
import { TCreatedVideo } from '../../../types/responses/video';
import { boolToText, textToBool, timeToString } from '../../../utils/common';
import { getVideoItemPath } from '../../../utils/paths';
import { pluralize } from '../../../utils/text-formatters';
import {
  Preview,
  SelectInner,
  SelectWrapper,
  TimingWrapper,
} from './components';

type TProps = {
  videoData: TCreatedVideo;
  editMode?: boolean;
  handleChangeVideoList?: (id: string) => void;
  selectedVideos?: string[];
};

const WrapperBase = styled('div')<{
  selectedVideo?: 'true' | 'false';
}>`
  position: relative;
  opacity: ${({ selectedVideo }) => (textToBool(selectedVideo) ? 1 : 0.5)};
`;

const WrapperLink = styled(NavLink)`
  position: relative;
`;

export const VideoColTextWrapper = styled('div')`
  color: ${({ theme }) => theme.COLORS.WHITE._100};
`;

export const VideoColText = styled(Typography.Title)`
  color: ${({ theme }) => theme.COLORS.WHITE._100} !important;
  margin-top: 0;
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  @media (max-width: 480px) {
    font-size: 16px !important;
  }
`;

export const VideoColDescription = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.COLORS.WHITE._100} !important;
  margin-top: 0;
  margin-bottom: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const VideoGridContentWrapper = styled('div')`
  display: grid;
  grid-template-columns: 3fr 5fr 4fr;
  gap: 20px;

  ${VideoColTextWrapper} {
    transition: opacity 0.3s ease-out;
  }

  &:hover {
    ${VideoColTextWrapper} {
      opacity: 0.6;
    }
  }

  @media (max-width: 948px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 480px) {
    gap: 10px;
  }
`;

const SpanItem = styled('div')`
  color: ${({ theme }) => theme.COLORS.BLACK._700};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const VideoColStatWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  gap: 16px;
`;

export const VideoColStatItem = styled('div')`
  padding: 18px 14px;
  background-color: ${({ theme }) => theme.COLORS.BLACK._400};
  border-radius: 16px;
  flex-basis: 50%;
`;

export const VideoColStatTitle = styled('div')`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  margin-bottom: 6px;

  @media (max-width: 480px) {
    font-size: 14px !important;
  }
`;

export const VideoColStatText = styled('div')`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.COLORS.BLACK._700};

  @media (max-width: 480px) {
    font-size: 12px !important;
  }
`;

export const VideoColItem: FC<TProps> = ({
  videoData: {
    id,
    createdAt,
    viewsCount,
    title,
    videoPreview,
    duration,
    description,
    updatedAt,
  },
  handleChangeVideoList,
  selectedVideos,
  editMode,
}) => {
  const curLanguage = useLocaleSelector();
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const isSelected = useMemo(
    () => selectedVideos && selectedVideos.includes(id),
    [selectedVideos, id]
  );

  const handleSelect = useCallback(() => {
    if (handleChangeVideoList) {
      handleChangeVideoList(id);
    }
  }, [handleChangeVideoList, id]);

  const havePreview = !!videoPreview?.url;

  const content = (
    <VideoGridContentWrapper>
      <Preview>
        <img
          src={havePreview ? videoPreview?.url : emptyVideoPreview}
          style={{
            objectFit: havePreview ? 'cover' : 'contain',
          }}
          alt={`Превью видео: ${title}`}
        />
        <TimingWrapper>{timeToString(Math.round(duration ?? 0))}</TimingWrapper>
      </Preview>
      <VideoColTextWrapper>
        <VideoColText level={3}>{title}</VideoColText>
        {description && (
          <VideoColDescription>{description}</VideoColDescription>
        )}
        <Space size={20}>
          <SpanItem>
            {viewsCount}{' '}
            {pluralize(viewsCount ?? 0, curLanguage.lang, [
              t('videos.viewing.one'),
              t('videos.viewing.many'),
              t('videos.viewing.few'),
            ])}
          </SpanItem>
        </Space>
      </VideoColTextWrapper>
      <VideoColStatWrapper>
        <VideoColStatItem>
          <VideoColStatTitle>
            {dayjs(createdAt).format('DD MMMM HH:mm')}
          </VideoColStatTitle>
          <VideoColStatText>{t('stats.created')}</VideoColStatText>
        </VideoColStatItem>
        <VideoColStatItem>
          <VideoColStatTitle>
            {dayjs(updatedAt).format('DD MMMM HH:mm')}
          </VideoColStatTitle>
          <VideoColStatText>{t('stats.updated')}</VideoColStatText>
        </VideoColStatItem>
      </VideoColStatWrapper>
    </VideoGridContentWrapper>
  );

  if (editMode) {
    return (
      <WrapperBase
        selectedVideo={boolToText(isSelected)}
        onClick={handleSelect}
      >
        <SelectWrapper placement="left">
          <SelectInner>
            <Checkbox className="plai-large-checkbox" checked={isSelected} />
          </SelectInner>
        </SelectWrapper>
        {content}
      </WrapperBase>
    );
  }

  return <WrapperLink to={getVideoItemPath(id)}>{content}</WrapperLink>;
};
