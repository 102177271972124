import { EditOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, ButtonProps, Input, Space } from 'antd';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

type Props = {
  initialValue: string;
  onSaveNew: (text: string) => void;
  okButtonProps?: ButtonProps;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
};

const Wrapper = styled('div')`
  display: inline-block;
`;

const EditButton = styled('div')`
  margin: 0 5px;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  vertical-align: middle;
`;

export const EditableText: FC<Props> = ({
  initialValue,
  onSaveNew,
  okButtonProps,
  setEditMode,
  editMode,
}) => {
  const [value, setValue] = useState<string>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const enterEditMode = () => {
    setEditMode(true);
  };

  const exitEditMode = () => {
    setEditMode(false);
  };

  const cancelEdition = () => {
    exitEditMode();
    setValue(initialValue);
  };

  const save = () => {
    onSaveNew(value);
  };

  return (
    <Wrapper>
      {editMode ? (
        <Space
          style={{
            marginTop: -12,
            marginLeft: 12,
          }}
        >
          <Input
            placeholder={initialValue}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            className="themed"
          />
          <Button type="primary" onClick={save} {...okButtonProps}>
            Сохранить
          </Button>
          <Button onClick={cancelEdition}>Отмена</Button>
        </Space>
      ) : (
        value
      )}
      {!editMode && (
        <EditButton onClick={enterEditMode}>
          <EditOutlined />
        </EditButton>
      )}
    </Wrapper>
  );
};
