import styled from '@emotion/styled';
import dayjs, { OpUnitType, QUnitType } from 'dayjs';
import { TFunction } from 'i18next';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoadAllAnalytics } from '../../../hooks/use-load-all-analytics';
import { LocaleKeys } from '../../../locale';
import {
  TAllStatLocal,
  TDynamicData,
} from '../../../state/analytics/dynamicsSlice';
import { useDynamicsDataSelector } from '../../../state/analytics/selectors';
import { extractStats } from '../../../utils/parse-stat';
import { TableLoader } from '../../table/table-loader';
import { ESimpleDateKey } from '../dates-list';
import { StatCard } from './stat-card';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 36px;
  grid-gap: 10px;
  min-height: 180px;
  position: relative;

  @media (max-width: 738px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

type TStatItem = {
  label: string;
  value: string;
  statChange: number | false;
  negative: boolean;
};

const getFiltersMock: (
  t: TFunction<string[]>,
  data: TAllStatLocal,
  dynamicData: TDynamicData
) => TStatItem[] = (t, data, dynamicData) => {
  const allWatchedCountDiff = extractStats(dynamicData.allWatchedCountDiff);
  const uploadedVideosDiff = extractStats(dynamicData.uploadedVideosDiff);
  const viewingDurationDiff = extractStats(dynamicData.viewingDurationDiff);
  const watchedTillEndCountDiff = extractStats(
    dynamicData.watchedTillEndCountDiff
  );

  let unit: QUnitType | OpUnitType = 'hours';

  if (data.viewingDuration < 60) {
    unit = 'seconds';
  } else if (data.viewingDuration < 3600) {
    unit = 'minutes';
  }

  return [
    {
      label: t('mainStats.views'),
      value: t('mainStats.countValue', {
        value: data?.allWatchedCount,
      }),
      statChange: allWatchedCountDiff[0] === 0 ? false : allWatchedCountDiff[1],
      negative: allWatchedCountDiff[0] < 0,
    },
    {
      label: t('mainStats.fullViews'),
      value: t('mainStats.countValue', {
        value: data.watchedTillEndCount,
      }),
      statChange:
        watchedTillEndCountDiff[0] === 0 ? false : watchedTillEndCountDiff[1],
      negative: watchedTillEndCountDiff[0] < 0,
    },
    {
      label: t('mainStats.time'),
      value: t(`mainStats.${unit}Value`, {
        value: dayjs()
          .add(data.viewingDuration, 'seconds')
          .diff(dayjs(), unit)
          .toLocaleString(),
      }),
      statChange: viewingDurationDiff[0] === 0 ? false : viewingDurationDiff[1],
      negative: viewingDurationDiff[0] < 0,
    },
    {
      label: t('mainStats.videosCount'),
      value: t('mainStats.videosValue', {
        value: data.uploadVideosCount,
      }),
      statChange: uploadedVideosDiff[0] === 0 ? false : uploadedVideosDiff[1],
      negative: uploadedVideosDiff[0] < 0,
    },
  ];
};

const StatsLoader = styled(TableLoader)`
  left: 0;
  right: 0;
  border-radius: 10px;
`;

export const Statistics: FC = () => {
  const { t } = useTranslation([LocaleKeys.ANALYTICS]);

  const { pending: allStatPending } = useLoadAllAnalytics();
  const { data, allStats, filters, pending } = useDynamicsDataSelector();

  const statList = useMemo(
    () => (data ? getFiltersMock(t, allStats, data) : undefined),
    [t, allStats, data]
  );

  return (
    <Wrapper>
      {(pending || allStatPending) && <StatsLoader />}
      {statList?.map((el) => (
        <StatCard
          key={el.label}
          statChange={el.statChange}
          label={el.label}
          value={el.value}
          negative={el.negative}
          t={t}
          period={filters?.period as ESimpleDateKey | undefined}
        />
      ))}
    </Wrapper>
  );
};
