import styled from '@emotion/styled';
import { DatePicker, Empty, message } from 'antd';
import Select from 'antd/lib/select';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { API } from '../../api';
import { useLoadAllAnalytics } from '../../hooks/use-load-all-analytics';
import { useLoadLinks } from '../../hooks/use-load-links';
import { useLoadVideos } from '../../hooks/use-load-videos';
import { LocaleKeys } from '../../locale';
import { useAppDispatch } from '../../state';
import { TExcelDownloadPayload } from '../../state/analytics/dynamicsSlice';
import {
  setDateFilterAction,
  setLinkFilterAction,
  setUserFilterAction,
  setVideoFilterAction,
} from '../../state/analytics/filtersSlice';
import {
  useAnalyticsFilterSelector,
  useDynamicsDataSelector,
} from '../../state/analytics/selectors';
import { TUserStats } from '../../types/responses/analytics';
import { boolToText, textToBool } from '../../utils/common';
import { TOption } from '../form-items/block-selectable';
import {
  ESimpleDateKey,
  getAnalyticsSimpleDatesFilterList,
} from './dates-list';
import { FilterPickItem } from './filter-pick-item';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 14px;
  margin-top: 16px;

  @media (max-width: 1010px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
  }

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const GroupWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  @media (max-width: 1010px) {
    &:first-of-type {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: auto;
      gap: 6px;
      grid-column: 1 / -1;

      & > *:nth-child(7) {
        grid-column: 1 / -1;
        justify-content: flex-end;
        width: 100%;
      }
    }
    &:last-child {
      justify-content: flex-end;
      max-width: 90px;
      align-items: center;
      gap: 6px;
    }
  }
  @media (max-width: 500px) {
    display: flex;
    flex-wrap: nowrap;
    &:first-of-type {
      width: 100%;
    }

    &:last-child {
      max-width: 332px;
      gap: 2px;
    }
  }
`;

const DownloadButtonWrapper = styled('div')`
  position: absolute;
  top: 20px;
  right: 70px;

  @media (max-width: 738px) {
    right: 12px;
    top: 12px;
  }
`;

const { RangePicker } = DatePicker;

const PickerWrapper = styled('div') <{
  isSelected: 'true' | 'false';
}>`
  .ant-picker {
    background-color: ${({ theme }) => theme.COLORS.BLACK._400};
    border-color: ${({ theme, isSelected }) =>
    textToBool(isSelected)
      ? theme.COLORS.ACCENT._200
      : theme.COLORS.BLACK._400};
    color: ${({ theme }) => theme.COLORS.WHITE._100};
    height: 100%;
    border-radius: 30px;
    width: 100%;

    .ant-picker-separator,
    .ant-picker-suffix,
    .ant-picker-clear {
      color: ${({ theme }) => theme.COLORS.WHITE._100};
    }

    .ant-picker-input > input {
      ::placeholder {
        color: ${({ theme }) => theme.COLORS.WHITE._100};
        opacity: 1; /* Firefox */
      }

      ::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: ${({ theme }) => theme.COLORS.WHITE._100};
      }
    }

    .ant-picker-input-placeholder > input {
      color: ${({ theme }) => theme.COLORS.WHITE._100};
    }
  }
`;

const DownloadButton = styled('button')`
  background-color: ${({ theme }) => theme.COLORS.ACCENT._200};
  border: 1px solid ${({ theme }) => theme.COLORS.ACCENT._200};
  cursor: pointer;
  border-radius: 30px;
  padding: 11px 16px;
  color: ${({ theme }) => theme.COLORS.BLACK._400};
  height: 40px;

  @media (max-width: 738px) {
    padding: 5px 16px;
  }
`;

export const FiltersList: FC = () => {
  const dispatch = useAppDispatch();
  const {
    selectedSimpleDateKey,
    startDate,
    endDate,
    videosId,
    linkId,
    userId,
  } = useAnalyticsFilterSelector();

  const { pending: allStatPending, data: allAnalyticsData } =
    useLoadAllAnalytics();
  const { pending: dynamicsPending } = useDynamicsDataSelector();

  const { t, i18n } = useTranslation([LocaleKeys.ANALYTICS]);

  const { data: videosData } = useLoadVideos();
  const { data: linksData } = useLoadLinks();

  const analyticsSimpleDatesFilterList = useMemo(() => {
    return getAnalyticsSimpleDatesFilterList(t);
  }, [t]);

  const handlePickSimpleFilterItem = useCallback(
    (itemKey: ESimpleDateKey) => {
      const dateItem = analyticsSimpleDatesFilterList.find(
        (el) => el.key === itemKey
      );

      if (dateItem) {
        dispatch(
          setDateFilterAction({
            key: itemKey,
            startDate: dateItem.dateStart,
            endDate: dateItem.dateEnd,
          })
        );
      }
    },
    [analyticsSimpleDatesFilterList]
  );

  const handleDateRangeChange = useCallback((values: unknown) => {
    if (values) {
      const myValues = values as [Dayjs, Dayjs];

      dispatch(
        setDateFilterAction({
          key: ESimpleDateKey.PERIOD,
          startDate: myValues[0].toISOString(),
          endDate: myValues[1].toISOString(),
        })
      );
    } else {
      dispatch(
        setDateFilterAction({
          key: undefined,
          startDate: undefined,
          endDate: undefined,
        })
      );
    }
  }, []);

  const handleSelectChange = useCallback(
    (values: string[], type: 'video' | 'link' | 'user') => {
      let actionType:
        | typeof setVideoFilterAction
        | typeof setLinkFilterAction
        | typeof setUserFilterAction;

      switch (type) {
        case 'video':
          actionType = setVideoFilterAction;
          break;
        case 'link':
          actionType = setLinkFilterAction;
          break;
        case 'user':
          actionType = setUserFilterAction;
          break;
      }

      dispatch(actionType(values));
    },
    []
  );

  const handleVideoChange = useCallback(
    (values: string[]) => {
      handleSelectChange(values, 'video');
    },
    [handleSelectChange]
  );

  const handleLinkChange = useCallback(
    (link: string[]) => {
      handleSelectChange(link, 'link');
    },
    [handleSelectChange]
  );

  const handleUserChange = useCallback(
    (users: string[]) => {
      handleSelectChange(users, 'user');
    },
    [handleSelectChange]
  );

  const handleDownloadReport = useCallback(async () => {
    const loading = message.loading(t('report.loading'), 0);

    try {
      let filters: TExcelDownloadPayload = {};

      if (selectedSimpleDateKey !== ESimpleDateKey.PERIOD) {
        filters.byPeriod = selectedSimpleDateKey;
        delete filters.fromDate;
        delete filters.toDate;
      } else {
        filters = {
          fromDate: startDate,
          toDate: endDate,
        };
      }

      const { data } = await API.analytics.downloadExcel(filters);

      const link = document.createElement('a');
      link.download = 'report.xlsx';
      link.href = window.URL.createObjectURL(data);
      link.click();
      link.remove();
    } catch (e) {
      message.error(t('report.error'));
    } finally {
      loading();
    }
  }, [selectedSimpleDateKey, startDate, endDate, t]);

  const usersOptions: TOption[] = useMemo(() => {
    if (!allAnalyticsData) {
      return [];
    }

    let users: TUserStats[] = [];

    Object.keys(allAnalyticsData.usersLinksStats).forEach((linkId) => {
      allAnalyticsData.usersLinksStats[linkId].forEach((user) => {
        if (!users.some((el) => el.userId === user.userId)) {
          users.push(user);
        }
      });
    });

    return users.map((el) => {
      return {
        value: el.userId,
        label: el.fullname ?? el.email,
      };
    }) as TOption[];
  }, [allAnalyticsData]);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 948);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Wrapper>
      <GroupWrapper>
        {analyticsSimpleDatesFilterList.map((el) => (
          <FilterPickItem
            onClick={handlePickSimpleFilterItem}
            key={el.key}
            dateKey={el.key}
            selected={el.key === selectedSimpleDateKey}
            disabled={allStatPending || dynamicsPending}
          >
            {el.name}
          </FilterPickItem>
        ))}
        <PickerWrapper
          isSelected={boolToText(
            selectedSimpleDateKey === ESimpleDateKey.PERIOD
          )}
        >
          <RangePicker
            format={'DD.MM.YYYY'}
            needConfirm={false}
            showTime
            value={
              startDate &&
                endDate &&
                selectedSimpleDateKey === ESimpleDateKey.PERIOD
                ? [dayjs(startDate), dayjs(endDate)]
                : undefined
            }
            onChange={handleDateRangeChange}
            locale={{
              // @ts-ignore
              lang: {
                locale: i18n.language,
                rangePlaceholder: [
                  t('filters.startDate'),
                  t('filters.endDate'),
                ],
                placeholder: t('filters.period'),
              },
            }}
          />
        </PickerWrapper>
      </GroupWrapper>
      <DownloadButtonWrapper>
        <DownloadButton onClick={handleDownloadReport}>
          {t('filters.downloadReport')}
        </DownloadButton>
      </DownloadButtonWrapper>
      <GroupWrapper>
        <Select
          options={videosData.map((el) => ({
            value: el.id,
            label: el.title,
          }))}
          placeholder={t('filters.videoPlaceholder')}
          className={`select-sm rounded${(videosId?.length ?? 0) > 0 ? ' selected' : ''
            }`}
          popupMatchSelectWidth={false}
          mode="multiple"
          value={videosId}
          onChange={handleVideoChange}
          maxTagCount={4}
          allowClear
          style={{
            minWidth: 90,
            maxWidth: 300,
          }}
          dropdownStyle={isMobile ? { maxWidth: 200 } : {}}
          filterOption={(value, option) =>
            !!option?.label
              ?.toLocaleLowerCase()
              ?.includes(value?.toLocaleLowerCase())
          }
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('base.empty')}
            />
          }
        />
        <Select
          options={linksData
            .filter((el) =>
              videosId && videosId.length > 0
                ? videosId.includes(el.videoId)
                : true
            )
            .map((el) => ({
              value: el.id,
              label: el?.videoSetting?.title,
            }))}
          placeholder={t('filters.linksPlaceholder')}
          className={`select-sm rounded${(linkId?.length ?? 0) > 0 ? ' selected' : ''
            }`}
          popupMatchSelectWidth={false}
          mode="multiple"
          value={linkId}
          onChange={handleLinkChange}
          maxTagCount={4}
          allowClear
          style={{
            minWidth: 100,
            maxWidth: 300,
          }}
          dropdownStyle={isMobile ? { maxWidth: 200 } : {}}
          filterOption={(value, option) =>
            !!option?.label
              ?.toLocaleLowerCase()
              ?.includes(value?.toLocaleLowerCase())
          }
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('base.empty')}
            />
          }
        />
        <Select
          options={usersOptions}
          placeholder={t('filters.usersPlaceholder')}
          className={`select-sm rounded${(userId?.length ?? 0) > 0 ? ' selected' : ''
            }`}
          popupMatchSelectWidth={false}
          mode="multiple"
          value={userId}
          onChange={handleUserChange}
          style={{
            minWidth: 140,
            maxWidth: 300,
          }}
          maxTagCount={4}
          allowClear
          filterOption={(value, option) =>
            !!option?.label
              ?.toLocaleLowerCase()
              ?.includes(value?.toLocaleLowerCase())
          }
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('base.empty')}
            />
          }
        />
      </GroupWrapper>
    </Wrapper>
  );
};
