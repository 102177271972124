import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { ContentWrapper } from './upload-video-layout.styled';

export const Content: FC<{
  layout: string;
}> = ({ layout }) => {
  return (
    <ContentWrapper layout={layout}>
      <Outlet />
    </ContentWrapper>
  );
};
