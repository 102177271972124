import { PlusOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Popconfirm, message } from 'antd';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { API } from '../api';
import { EditableText } from '../components/common/editable-text';
import { PageTitle } from '../components/common/page-title';
import {
  LS_LAYOUT_VALUE_LEY_PLAYLIST_LIST_PAGE,
  LS_LAYOUT_VALUE_LEY_PLAYLIST_PAGE,
  TitleWithLayout,
} from '../components/common/title-with-layout';
import { ContentWrapper } from '../components/layout/upload-video-layout/upload-video-layout.styled';
import { PreviewWrapper } from '../components/uploaded-videos/uploaded-items/components';
import { VideoColItem } from '../components/uploaded-videos/uploaded-items/video-col-item';
import { VideoItem } from '../components/uploaded-videos/uploaded-items/video-item';
import { useHandleLoadPlaylist } from '../hooks/playlists';
import { useLocalStorageValue } from '../hooks/use-local-storage-value';
import { usePlayListPathId } from '../hooks/use-playlist-path-id';
import { LocaleKeys } from '../locale';
import { useAppDispatch } from '../state';
import { playlistCurrentActions } from '../state/playlists/currentSlice';
import { playListAllActions } from '../state/playlists/listSlice';
import { usePlaylistCurrentSelector } from '../state/playlists/selectors';
import { Paths } from '../types/common';
import { TPlayListEditForm } from '../types/responses/video';
import { EVideoQueryMods, EVideoQueryPaths } from '../types/video';

const ButtonsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 60px;
  flex-wrap: wrap;
  margin-bottom: 40px;
`;

const Row = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const AddButtonContent = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
`;

const AddButtonWrapper = styled('div')<{ layout: string }>`
  display: ${({ layout }) => (layout.includes('grid') ? 'block' : 'grid')};
  grid-template-columns: ${({ layout }) =>
    layout.includes('grid') ? 'unset' : '3fr 5fr 4fr'};
  position: relative;
`;

const AddButton = styled('div')<{ layout: string }>`
  border-radius: 20px;
  padding-top: ${({ layout }) => (layout.includes('grid') ? '60%' : '0')};
  position: ${({ layout }) =>
    layout.includes('grid') ? 'relative' : 'absolute'};
  top: ${({ layout }) => (layout.includes('grid') ? 'unset' : '0')};
  bottom: ${({ layout }) => (layout.includes('grid') ? 'unset' : '0')};
  left: ${({ layout }) => (layout.includes('grid') ? 'unset' : '0')};
  right: ${({ layout }) => (layout.includes('grid') ? 'unset' : '0')};
  color: ${({ theme }) => theme.COLORS.BLACK._700};
  height: ${({ layout }) => (layout.includes('grid') ? '0' : '100%')};
  border: 2px dashed ${({ theme }) => theme.COLORS.BLACK._500};
  cursor: pointer;
  transition:
    border-color 0.3s ease-out,
    color 0.3s ease-out;

  &:hover {
    border-color: ${({ theme }) => theme.COLORS.ACCENT._200};
    color: ${({ theme }) => theme.COLORS.ACCENT._200};
  }
`;

export const PlaylistPage: FC = () => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const id = usePlayListPathId();
  const dispatch = useAppDispatch();
  const pendingRef = useRef(false);
  const navigate = useNavigate();

  const { data, pending, error, loaded } = usePlaylistCurrentSelector();

  const [titleButtonLoading, setTitleButtonLoading] = useState(false);
  const [titleEditMode, setTitleEditMode] = useState<boolean>(false);

  const [deletePending, setDeletePending] = useState(false);

  const [layout, setLayout] = useLocalStorageValue<
    'colplaylist' | 'gridplaylist'
  >('gridplaylist', LS_LAYOUT_VALUE_LEY_PLAYLIST_LIST_PAGE);

  const handleLoadPlaylist = useHandleLoadPlaylist();

  useEffect(() => {
    if (
      (!loaded && !pending && !error && !pendingRef.current && id) ||
      (!pendingRef.current && !pending && loaded && data?.id !== id && id)
    ) {
      pendingRef.current = true;
      void handleLoadPlaylist(id);
    }
  }, [loaded, pending, error, id, data]);

  const handleEditPlayList = useCallback(
    async (editData: Partial<TPlayListEditForm>) => {
      const loading = message.loading(t('playlist.changing.loading'), 0);
      setTitleButtonLoading(true);

      if (!data) {
        message.error(t('playlist.loading'));
        setTitleButtonLoading(false);
        return;
      }

      try {
        const response = await API.playlists.edit(
          {
            ...data,
            videoIds: data.videos.map((el) => el.id),
            ownerId: data.owner?.id ?? null,
            ...editData,
          },
          data.id
        );

        loading();
        message.success(t('playlist.changing.success'));
        setTitleButtonLoading(false);
        setTitleEditMode(false);

        dispatch(playlistCurrentActions.editPlayList(response.data));
        dispatch(playListAllActions.resetState());
      } catch (e) {
        loading();
        message.error(t('playlist.changing.error'));
        setTitleButtonLoading(false);
      }
    },
    [data, t]
  );

  const handleSaveTitle = useCallback(
    (newTitle: string) => {
      if (newTitle && newTitle.length > 0 && newTitle.trim().length > 0) {
        void handleEditPlayList({
          title: newTitle,
        });
      } else {
        void message.warning(t('playlist.changing.warning'));
      }
    },
    [handleEditPlayList, t]
  );

  const handleShare = useCallback(() => {
    try {
      navigator.clipboard.writeText(window.location.href).then(() => {
        message.success(t('playlist.copy.success'));
      });
    } catch (e) {
      message.error(t('playlist.copy.error'));
    }
  }, [id, t]);

  const handleAddVideo = useCallback(() => {
    navigate(
      `${Paths.UPLOADED_VIDEOS}?${EVideoQueryPaths.PLAYLIST_ID}=${
        id ?? 'not-found'
      }&${EVideoQueryPaths.MODE}=${EVideoQueryMods.ADD_VIDEO}`
    );
  }, [id]);

  const handleDeletePlayList = useCallback(async () => {
    if (id) {
      const loading = message.loading(t('playlist.deleting.loading'), 0);
      setDeletePending(true);

      try {
        await API.playlists.delete(id);

        loading();
        dispatch(playListAllActions.resetState());
        message.success(t('playlist.deleting.success'));
        setDeletePending(false);
        navigate(Paths.UPLOADED_VIDEOS_PLAYLIST);
        dispatch(playlistCurrentActions.resetState());
      } catch (e) {
        loading();
        message.error(t('playlist.deleting.error'));
        setDeletePending(false);
      }
    }
  }, [id, t]);

  return (
    <div>
      <TitleWithLayout layout={layout} onChange={setLayout} />
      <ButtonsWrapper>
        <Row>
          <PageTitle> {t('playlist.title')}</PageTitle>
          <EditableText
            initialValue={data?.title ?? ''}
            onSaveNew={handleSaveTitle}
            okButtonProps={{
              loading: titleButtonLoading,
            }}
            editMode={titleEditMode}
            setEditMode={setTitleEditMode}
          />
        </Row>
        {/* <Button type="primary" onClick={handleShare}>
          {t('playlist.copy.share')}
        </Button> */}
        <Row>
          <Button onClick={handleAddVideo}>{t('playlist.change')}</Button>
          <Popconfirm
            title={t('playlist.deleting.title')}
            description={t('playlist.deleting.description')}
            onConfirm={handleDeletePlayList}
            okText={t('buttons.deleting.success')}
            cancelText={t('buttons.cancel')}
            okButtonProps={{
              danger: true,
              loading: deletePending,
            }}
            cancelButtonProps={{
              type: 'primary',
              loading: deletePending,
            }}
            placement="right"
          >
            <Button danger loading={deletePending}>
              {t('playlist.delete')}
            </Button>
          </Popconfirm>
        </Row>
      </ButtonsWrapper>
      <ContentWrapper layout={layout}>
        {data?.videos.map((el) =>
          layout.includes('grid') ? (
            <VideoItem key={el.id} videoData={el} />
          ) : (
            <VideoColItem key={el.id} videoData={el} />
          )
        )}
        <AddButtonWrapper layout={layout}>
          {layout.includes('col') && <PreviewWrapper />}
          <AddButton onClick={handleAddVideo} layout={layout}>
            <AddButtonContent>
              <PlusOutlined />
            </AddButtonContent>
          </AddButton>
        </AddButtonWrapper>
      </ContentWrapper>
    </div>
  );
};
