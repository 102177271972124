import { InboxOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Space, Upload, UploadFile, UploadProps } from 'antd';
import App from 'antd/lib/app';
import { AxiosResponse } from 'axios';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { API } from '../api';
import { PageBackButton } from '../components/common/page-back-button';
import { DescriptionEdit } from '../components/video-page/description-edit';
import { TimeLineControl } from '../components/video-page/time-line-control';
import { VideoPlayer } from '../components/video-player';
import { DEFAULT_PREVIEW_LINK } from '../constants/default-preview-link';
import { LocaleKeys } from '../locale';
import { useAppDispatch } from '../state';
import {
  createVideoResetStateAction,
  createVideoSetSecondStageAction,
} from '../state/video/createSlice';
import { resetVideoListState } from '../state/video/listSlice';
import { useCreateVideoSelector } from '../state/video/selectors';
import { Paths } from '../types/common';
import {
  TCreatePreviewResponse,
  TUploadVideoResponse,
  TVideoCreateForm,
} from '../types/responses/video';
import { covertImageToBlob } from '../utils/covertImageToBlob';
import { getVideoItemPath } from '../utils/paths';
import { VideoEditVideoWrapper } from './video-edit';
import previewImage from '/src/assets/default-preview.png';

const { Dragger } = Upload;

const ButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
`;

const MainWrapper = styled('div')`
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 50px;
  margin-top: 80px;

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
    margin-top: 40px;
  }
`;

export const VideoCreate: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { t } = useTranslation([LocaleKeys.VIDEO]);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const { message, notification } = App.useApp();

  const { currentStage, firstStage, secondStage } = useCreateVideoSelector();

  const handleDeleteVideo = useCallback(async () => {
    try {
      if (!firstStage.uploadByLinkWithoutSaveVideoId) {
        return;
      }

      await API.videos.deleteById(firstStage.uploadByLinkWithoutSaveVideoId);
    } catch (e) {
      console.error(e);
    }
  }, [firstStage, t]);

  const handleReset = useCallback(() => {
    dispatch(createVideoResetStateAction());
    handleDeleteVideo();
  }, []);

  useEffect(() => {
    if (currentStage === 'first') {
      navigate(Paths.UPLOADED_VIDEOS);
    }
  }, [currentStage]);

  const handleCreatePreview: () => Promise<
    TCreatePreviewResponse | never | void
  > = useCallback(async () => {
    if (fileList.length === 1 && fileList[0]) {
      try {
        const formData = new FormData();

        formData.set('file', fileList[0] as unknown as Blob);
        formData.set('mediaType', 'image');

        const uploadResponse: AxiosResponse<TUploadVideoResponse> =
          await API.videos.upload(formData).catch(() => {
            throw new Error('upload-error');
          });

        const createPreviewResponse: AxiosResponse<TCreatePreviewResponse> =
          await API.videos
            .createPreview({
              url: uploadResponse.data.originUrls,
              mediaType: 'image',
            })
            .catch(() => {
              throw new Error('create-preview-error');
            });

        return createPreviewResponse.data;
      } catch (e) {
        message.error(t('newVideo.previewUploadError'));
      }
    }

    throw new Error('empty-payload');
  }, [fileList, t]);

  const stopCreating = () => {
    dispatch(
      createVideoSetSecondStageAction({
        pending: false,
        uploaded: false,
      })
    );
  };

  const processcreatePreview: () => Promise<string | undefined> =
    useCallback(async () => {
      return new Promise<string | undefined>((resolve) => {
        const processCanvasPreview = () => {
          if (context && videoRef.current) {
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            context.drawImage(
              videoRef.current,
              0,
              0,
              videoRef.current.videoWidth,
              videoRef.current.videoHeight
            );

            canvas.toBlob(
              (blob) => uploadPreview(blob, 'canvas-preview.jpg'),
              'image/jpeg'
            );
          } else {
            processDefaultPreview(resolve);
          }
        };

        const processDefaultPreview = async (
          resolveFn: (value: string | undefined) => void
        ) => {
          try {
            const previewBlob = await covertImageToBlob(previewImage);
            if (previewBlob) {
              await uploadPreview(previewBlob, 'default-preview.png');
            } else {
              throw new Error('Default preview blob is null');
            }
          } catch (error) {
            console.error('Error processing default preview:', error);
            resolveFn(undefined);
          }
        };

        if (!videoRef.current) {
          // Если видео недоступно, сразу создаем дефолтное превью
          processDefaultPreview(resolve);
          return;
        }

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        videoRef.current.crossOrigin = 'anonymous';

        const uploadPreview = async (blob: Blob | null, filename: string) => {
          try {
            if (!blob) {
              throw new Error('Blob is null');
            }

            const formData = new FormData();
            formData.set('file', blob, filename);
            formData.set('mediaType', 'image');

            const uploadResponse = await API.videos.upload(formData);
            const previewUrl = uploadResponse?.data.originUrls ?? '';

            if (!previewUrl) {
              throw new Error('Upload response missing originUrls');
            }

            const createPreviewResponse = await API.videos.createPreview({
              url: previewUrl,
              mediaType: 'image',
            });

            resolve(createPreviewResponse?.data.id || previewUrl);
          } catch (error) {
            console.error('Error uploading or creating preview:', error);
            processDefaultPreview(resolve);
          }
        };

        processCanvasPreview();
      });
    }, [videoRef]);

  const handleSave = useCallback(async () => {
    if (!secondStage?.form?.title) {
      message.error(t('newVideo.titleRequired'));
      return;
    }

    if (firstStage.uploaded && firstStage.uploadResponse && secondStage.form) {
      try {
        dispatch(
          createVideoSetSecondStageAction({
            pending: true,
            filled: true,
          })
        );

        const updateVideoUploadedByUrlWithoutSave = async (
          previewId?: string
        ) => {
          try {
            let previewToUpload = previewId;

            if (!previewToUpload && videoRef.current) {
              previewToUpload = await processcreatePreview();
            }

            await API.videos.editVideo(
              {
                title: secondStage.form.title,
                description: secondStage.form.description,
                videoPreviewId: previewToUpload,
              },
              firstStage.uploadByLinkWithoutSaveVideoId || ''
            );

            navigate(
              getVideoItemPath(firstStage.uploadByLinkWithoutSaveVideoId || '')
            );

            // message.loading(t('newVideo.createProcessing'), 0);

            dispatch(resetVideoListState());
            setTimeout(() => {
              dispatch(
                createVideoSetSecondStageAction({
                  pending: false,
                  uploaded: true,
                })
              );
              dispatch(createVideoResetStateAction());
            }, 200);
          } catch (e) {
            dispatch(
              createVideoSetSecondStageAction({
                pending: false,
                uploaded: false,
              })
            );
            // message.loading(t('newVideo.createProcessing'), 0);
            message.error(t('newVideo.createError'));
          }
        };

        const createVideo = async (previewId?: string) => {
          try {
            // message.loading(t('newVideo.createProcessing'), 0);

            let previewToUpload = previewId;

            if (!previewToUpload && videoRef.current) {
              previewToUpload = await processcreatePreview();
            }

            if (!previewToUpload) {
              // В случае ошибки создаем видео без превью
              previewToUpload = DEFAULT_PREVIEW_LINK; // Или путь к дефолтному изображению
            }

            const dataToPayload: TVideoCreateForm = {
              ...secondStage.form,
              videoPreviewId: previewToUpload,
              playerMode: 'customSettings',
              s3Keys: firstStage?.uploadResponse?.s3Keys as string,
              originUrls: firstStage?.uploadResponse?.originUrls as string,
              duration: firstStage.videoDuration || 0,
              size: firstStage.uploadResponse
                ? firstStage.uploadResponse.size
                : secondStage.form.size,
            };

            const videoId = await API.videos.createVideo(dataToPayload);

            navigate(getVideoItemPath(videoId.data));

            dispatch(resetVideoListState());

            setTimeout(() => {
              dispatch(
                createVideoSetSecondStageAction({
                  pending: false,
                  uploaded: true,
                })
              );
              dispatch(createVideoResetStateAction());
            }, 200);
          } catch (e) {
            dispatch(
              createVideoSetSecondStageAction({
                pending: false,
                uploaded: false,
              })
            );
            // message.loading(t('newVideo.createProcessing'), 0);
            message.error(t('newVideo.createError'));
          }
        };

        const catchPreviewErrors = (e: Error) => {
          const key = `preview-warning-${new Date().getDate()}`;
          const destroyPopup = () => {
            notification.destroy();
          };
          switch (e.message) {
            case 'empty-payload':
              notification.warning({
                message: t('newVideo.emptyPreview'),
                description: t('newVideo.emptyPreviewDescription'),
                closeIcon: false,
                btn: (
                  <Space>
                    <Button
                      type="link"
                      size="small"
                      onClick={() => {
                        destroyPopup();
                        stopCreating();
                      }}
                    >
                      {t('newVideo.addPreview')}
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        destroyPopup();
                        if (firstStage.uploadByLinkWithoutSaveVideoId) {
                          updateVideoUploadedByUrlWithoutSave();
                        } else createVideo();
                      }}
                    >
                      {t('newVideo.continueWithoutPreview')}
                    </Button>
                  </Space>
                ),
                key: key,
                duration: 0,
              });
              break;
            case 'upload-error':
              notification.error({
                message: t('newVideo.previewLoadError'),
                description: t('newVideo.previewLoadErrorContinue'),
                closeIcon: false,
                btn: (
                  <Space>
                    <Button
                      type="link"
                      size="small"
                      onClick={() => {
                        destroyPopup();
                        handleCreatePreview().catch(catchPreviewErrors);
                      }}
                    >
                      {t('newVideo.previewRetry')}
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        destroyPopup();
                        if (firstStage.uploadByLinkWithoutSaveVideoId) {
                          updateVideoUploadedByUrlWithoutSave();
                        } else createVideo();
                      }}
                    >
                      {t('newVideo.continueWithoutPreview')}
                    </Button>
                  </Space>
                ),
                key: key,
                duration: 0,
              });
              break;
            case 'create-preview-error':
              notification.error({
                message: t('newVideo.previewCreateError'),
                description: t('newVideo.previewLoadErrorContinue'),
                closeIcon: false,
                btn: (
                  <Space>
                    <Button
                      type="link"
                      size="small"
                      onClick={() => {
                        destroyPopup();
                        handleCreatePreview().catch(catchPreviewErrors);
                      }}
                    >
                      {t('newVideo.previewRetry')}
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        destroyPopup();
                        if (firstStage.uploadByLinkWithoutSaveVideoId) {
                          updateVideoUploadedByUrlWithoutSave();
                        } else createVideo();
                      }}
                    >
                      {t('newVideo.continueWithoutPreview')}
                    </Button>
                  </Space>
                ),
                key: key,
                duration: 0,
              });
              break;
            default:
              notification.error({
                message: t('newVideo.previewUnhandledError'),
                description: t('newVideo.previewLoadErrorContinue'),
                closeIcon: false,
                btn: (
                  <Space>
                    <Button
                      type="link"
                      size="small"
                      onClick={() => {
                        destroyPopup();
                        handleCreatePreview().catch(catchPreviewErrors);
                      }}
                    >
                      {t('newVideo.previewRetry')}
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        destroyPopup();
                        if (firstStage.uploadByLinkWithoutSaveVideoId) {
                          updateVideoUploadedByUrlWithoutSave();
                        } else createVideo();
                      }}
                    >
                      {t('newVideo.continueWithoutPreview')}
                    </Button>
                  </Space>
                ),
                key: key,
                duration: 0,
              });
          }
        };

        const previewResponse =
          await handleCreatePreview().catch(catchPreviewErrors);

        if (previewResponse && previewResponse.id) {
          if (firstStage.uploadByLinkWithoutSaveVideoId) {
            await updateVideoUploadedByUrlWithoutSave(previewResponse.id);
          } else await createVideo(previewResponse.id);
        }
      } catch (e) {
        dispatch(
          createVideoSetSecondStageAction({
            pending: false,
            uploaded: false,
          })
        );
        // message.loading(t('newVideo.createProcessing'), 0);
        message.error(t('newVideo.createError'));
      }
    } else {
      message.error(t('newVideo.someDataEmpty'));
    }
  }, [secondStage, firstStage, message, handleCreatePreview, t]);

  const draggerProps: UploadProps = {
    name: 'file',
    multiple: false,
    accept: 'image/',
    beforeUpload: (file) => {
      setFileList([file]);

      return false;
    },
  };

  return (
    <div>
      <PageBackButton onClick={handleReset} text={t('newVideo.back')} />
      <MainWrapper>
        <VideoEditVideoWrapper>
          {firstStage.uploadResponse && (
            <>
              <VideoPlayer
                videoSrc={
                  firstStage.uploadByLinkWithoutSaveVideoId &&
                    firstStage.uploadByLinkWithoutSaveVideoLink
                    ? firstStage.uploadByLinkWithoutSaveVideoLink
                    : firstStage.uploadResponse?.originUrls
                }
                ref={videoRef}
              />
            </>
          )}
        </VideoEditVideoWrapper>
        <div>
          <DescriptionEdit isCreateMode={true} />
          <div
            style={{
              minHeight: 220,
              marginTop: 20,
            }}
          >
            <Dragger
              {...draggerProps}
              fileList={fileList}
              onRemove={() => {
                setFileList([]);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('newVideo.uploadPreview')}</p>
            </Dragger>
          </div>
          <ButtonsWrapper>
            <Button type="primary" size="large" onClick={handleSave}>
              {t('newVideo.save')}
            </Button>
            <Button type="primary" size="large" danger onClick={handleReset}>
              {t('newVideo.delete')}
            </Button>
          </ButtonsWrapper>
        </div>
      </MainWrapper>
    </div>
  );
};
