import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

import { BaseButton } from '../../common/base-button';
import { BaseBlock, BlockBaseText, BlockBaseTitle } from '../../common/blocks';

export const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: minmax(140px, 1fr) minmax(140px, 1fr);
  grid-template-rows: repeat(auto-fill, minmax(140px, 1fr));
  row-gap: 20px;
  column-gap: 20px;
  padding-right: 40px;
  height: fit-content;
`;

export const MainBlockTitle = styled('div')`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.COLORS.WHITE._600};
`;

export const MainBlock = styled(BaseBlock)`
  padding-inline: 20px;
  background-color: ${({ theme }) => theme.COLORS.BLACK._300};
  padding-bottom: 18px;
  padding-top: 12px;
  grid-column-end: span 3;

  .input-share {
    margin-top: 14px;
    margin-bottom: 12px;
  }
`;

const LinkLikeText = styled('div')`
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-decoration-line: underline;
  width: fit-content;
  color: ${({ theme }) => theme.COLORS.BLACK._700};
`;

export const CopyTextItem = styled(LinkLikeText)`
  margin: 0 auto;
`;

export const ActionTextItem = styled(LinkLikeText)`
  :not(:last-of-type) {
    margin-bottom: 14px;
  }
`;

export const ActionsWrapper = styled('div')`
  margin-top: 22px;
`;

export const BaseTitle = styled(BlockBaseTitle)`
  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const BaseText = styled(BlockBaseText)``;

export const BlockText = styled('div')`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: ${({ theme }) => theme.COLORS.WHITE._500};
`;

export const AddLinkWrapperDiv = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding-inline: 30px;
  padding-block: 35px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.COLORS.BLACK._300};
  transition: border-color 0.3s ease-out;
  background-color: ${({ theme }) => theme.COLORS.BLACK._300};
  grid-column-end: span 2;
  border-radius: 20px;

  &:hover {
    border-color: ${({ theme }) => theme.COLORS.ACCENT._200};
  }

  .plus-ico {
    width: 38px;
    height: auto;
  }
`;

export const AddLinkWrapper = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding-inline: 30px;
  padding-block: 35px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.COLORS.BLACK._300};
  transition: border-color 0.3s ease-out;
  background-color: ${({ theme }) => theme.COLORS.BLACK._300};
  grid-column-end: span 2;
  border-radius: 20px;

  &:hover {
    border-color: ${({ theme }) => theme.COLORS.ACCENT._200};
  }

  .plus-ico {
    width: 38px;
    height: auto;
  }
`;

export const ButtonsBlock = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-block: 20px;
  padding-inline: 14px;
`;

export const AboutButton = styled(BaseButton)`
  font-size: 26px;

  svg {
    width: 26px;
    height: auto;
  }
`;
