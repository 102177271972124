import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import React, { FC, useMemo, useRef } from 'react';

import { PageBackButton } from '../components/common/page-back-button';
import { StatsDescription } from '../components/video-page/stats-description';
import { TimeLineControl } from '../components/video-page/time-line-control';
import { VideoPlayer } from '../components/video-player';
import { useLoadVideo } from '../hooks/use-load-video';
import { useVideoPathId } from '../hooks/use-video-path-id';
import mockVideo from '../mocks/mock-video.mp4';
import { mockVideoList } from '../mocks/uploaded';
import { useVideoSelector } from '../state/video/selectors';

const Wrapper = styled('div')`
  padding-bottom: 60px;
  padding-right: 40px;
`;

const ContentWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 60px;
  margin-top: 80px;
`;

const VideoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 38px;
`;

const LoadingWrapper = styled('div')`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 54px;
  min-height: 340px;
`;

const RightSide = styled('div')`
  padding-right: 40px;
`;

export const VideoStatistics: FC = () => {
  const id = useVideoPathId();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { data, loading } = useVideoSelector();

  const fondedMockVideo = useMemo(() => {
    return mockVideoList.find((el) => el.id === id) ?? mockVideoList[0];
  }, [id]);

  useLoadVideo();

  return (
    <Wrapper>
      <PageBackButton text="Загруженные видео" />
      {loading ? (
        <LoadingWrapper>
          <LoadingOutlined />
        </LoadingWrapper>
      ) : (
        <ContentWrapper>
          <VideoWrapper>
            <VideoPlayer
              videoSrc={data?.originUrls ?? mockVideo}
              ref={videoRef}
              LineVisible={false}
              preview={
                fondedMockVideo
                  ? {
                    url: fondedMockVideo.previewUrl,
                    alt: `Превью видео: ${fondedMockVideo.name}`,
                  }
                  : undefined
              }
            />
            <TimeLineControl showByStats={true} ref={videoRef} />
          </VideoWrapper>
          <RightSide></RightSide>
        </ContentWrapper>
      )}
      <StatsDescription />
    </Wrapper>
  );
};
